@import "shared/styles/index";

.viewingContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 12px 12px;
  background-color: $color-white;
  box-shadow: 2px 2px 4px $color-dark-green;
}

.promptContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.canvasContainer {
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
