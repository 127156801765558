@import "shared/styles/index.scss";

.small {
  color: $color-black;
  font-size: 19px;
}

.classic {
  color: $color-grey;
  font-size: 25px;
}

.headerText {
  color: white;
  font-size: 45px;
  @include mobile {
    font-size: 25px;
  }
}
