@import "shared/styles/index.scss";

.photoButton {
  display: flex;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  border: none;
  border: 2px solid $color-dark-green;
  margin-top: 20px;
  background: none;
  border-radius: 20%;
  box-shadow: 2px 2px 4px $color-dark-green;
  color: $color-dark-green;
  cursor: pointer;
  font-family: $ff-robo;
  font-size: 80px;
  outline: none;
  transition: 1s;
}

.photoButton:hover {
  transform: scale(1.1);
}

input.invisibleFileInput {
  display: none;
}
