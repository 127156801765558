@import "shared/styles/index.scss";

button {
  color: $color-black;
  font-size: 16px;
}

.colorPickerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPicker {
  visibility: hidden;
}

.colorPickerButton {
  padding: 5px;
  margin-right: 20px;
  background-color: $color-white;
  box-shadow: 1px 1px 3px $color-black;
  cursor: pointer;
  font-family: $ff-robo;
}

.colorPickerButton:hover {
  background-color: $color-dark-grey;
}

.colorShower {
  outline: none;
  user-select: none;
  border: none;
  width: 25px;
  height: 25px;
  margin-left: 20px;
  border-radius: 50%;
}
