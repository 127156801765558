@import "shared/styles/index.scss";

.sizerContainer {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 20px;
  background-color: $color-mildly-dark-grey;
  box-shadow: 1px 1px 2px $color-black;
}

input[type="range"].sizeScroll {
  width: 160px;
  margin: 18px 0;
  appearance: none;
}
input[type="range"].sizeScroll:focus {
  outline: none;
}
input[type="range"].sizeScroll::-webkit-slider-runnable-track {
  width: 160px;
  height: 8.4px;
  border: 0.2px solid #010101;
  background: $color-green;
  border-radius: 1.3px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
  cursor: pointer;
}
input[type="range"].sizeScroll::-webkit-slider-thumb {
  width: 13px;
  height: 30px;
  border: 1px solid #000;
  margin-top: -14px;
  appearance: none;
  background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
  cursor: pointer;
}
input[type="range"].sizeScroll:focus::-webkit-slider-runnable-track {
  background: $color-green;
}
input[type="range"].sizeScroll::-moz-range-track {
  width: 160px;
  height: 8.4px;
  border: 0.2px solid #010101;
  background: $color-green;
  border-radius: 1.3px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
  cursor: pointer;
}
input[type="range"].sizeScroll::-moz-range-thumb {
  width: 13px;
  height: 30px;
  border: 1px solid #000;
  background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
  cursor: pointer;
}
input[type="range"].sizeScroll::-ms-track {
  width: 100%;
  height: 8.4px;
  border-width: 16px 0;
  border-color: transparent;
  background: transparent;
  color: transparent;
  cursor: pointer;
}
input[type="range"].sizeScroll::-ms-fill-lower {
  border: 0.2px solid #010101;
  background: #2a6495;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}
input[type="range"].sizeScroll::-ms-fill-upper {
  border: 0.2px solid #010101;
  background: $color-green;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}
input[type="range"].sizeScroll::-ms-thumb {
  width: 13px;
  height: 30px;
  border: 1px solid #000;
  background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
  cursor: pointer;
}
input[type="range"].sizeScroll:focus::-ms-fill-lower {
  background: $color-green;
}
input[type="range"].sizeScroll:focus::-ms-fill-upper {
  background: #367ebd;
}

.sizeInfo {
  margin-right: 35px;
  margin-left: 35px;
  color: black;
  font-size: 20px;
}

.sizeInfoSmall {
  font-size: 17px;
}
