@import "../../../shared/styles/index.scss";

.clickedButton {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: none;
  background-color: $color-green;
  color: $color-white;
  font-family: $ff-robo;
  font-size: 18px;
  outline: none;
  transition: 1s;
  user-select: none;
}

.unclickedButton {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: none;
  color: black;
  background-color: $color-mildly-dark-grey;
  cursor: pointer;
  font-family: $ff-robo;
  font-size: 18px;
  outline: none;
  transition: 1s;
  user-select: none;
}

.casteButton {
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 1px 1px 3px black;
}

.casteBottomButton {
  width: 100%;
  box-shadow: 1px 1px 3px black;
}

.selectUnselectButton {
  width: 50%;
  border-left: 1px solid rgb(0, 0, 0, 0.2);
}

.unclickedButton:hover {
  background-color: $color-dark-grey;
}
