@import "shared/styles/index";

.extractButton {
  color: black;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: none;
  margin-top: 20px;
  background: none;
  background-color: $color-mildly-dark-grey;
  box-shadow: 1px 1px 2px $color-black;
  cursor: pointer;
  font-family: $ff-robo;
  outline: none;
  transition: 1s;
  user-select: none;
}

.extractButton:hover {
  background-color: $color-dark-grey;
}

.extractButton:active {
  background-color: $color-mildly-dark-grey;
  transition: 0.1s;
}

.exportText {
  font-size: 18px;
}

.extractLink {
  display: none;
}
