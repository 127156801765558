@import "shared/styles/index.scss";

.rowContainer {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}
