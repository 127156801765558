@import "shared/styles/index";

.canvas {
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
  touch-action: none;
}

.mobileViewClass {
  display: flex;
  flex-grow: 1;
  width: 100%;

  :global {
    .react-transform-wrapper {
      width: unset;
      height: unset;
      display: flex;
      flex-grow: 1;
    }
    .react-transform-content {
      display: flex;
      flex-grow: 1;
    }
  }
}
