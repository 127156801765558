@import "../../../shared/styles/index.scss";

.header {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  background-color: $color-dark-green;
  box-shadow: 1px 1px 6px $color-black;
  color: $color-black;
}

.flagButton {
  display: flex;
  width: 60px;
  height: 60px;
  outline: none;
  border: none;
  background: none;
  position: absolute;
  top: 210px;
  @include mobile {
    top: 110px;
  }
  visibility: hidden;
  transition: 1.2s;
  background-size: 100%;
  z-index: 2;
}

.flagButton:hover {
  transition: 1s;
  transform: scale(1.1);
}

.headerText {
  margin-left: 8%;
}

.flagSelect {
  margin-left: 8%;
}

.flagSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.polandAnimated {
  top: 260px;
  @include mobile {
    top: 160px;
  }
  visibility: visible;
}

.germanyAnimated {
  top: 310px;
  @include mobile {
    top: 210px;
  }
  visibility: visible;
}

.britainAnimated {
  top: 360px;
  @include mobile {
    top: 260px;
  }
  visibility: visible;
}

.italyAnimated {
  top: 410px;
  @include mobile {
    top: 310px;
  }
  visibility: visible;
}

.franceAnimated {
  top: 460px;
  @include mobile {
    top: 360px;
  }
  visibility: visible;
}

.globeButton {
  border: none;
  outline: none;
  background: none;
  z-index: 3;
}

.globeLogo {
  width: 60px;
  height: 60px;
}

.invisibleButton {
  position: absolute;
  background: none;
  border: none;
  outline: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
}
