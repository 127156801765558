@import "../../shared/styles/index";

@keyframes arrive {
  from {
    margin-top: 100vh;
  }
  to {
    margin-top: 0;
  }
}

@keyframes hide {
  from {
    margin-top: 0;
  }
  to {
    margin-top: 100vh;
  }
}

.mainContainer {
  overflow: scroll;
  width: 300px;
  height: calc(100% - 12px);
  box-sizing: border-box;
  padding: 0 7px 12px 12px;

  @include mobile {
    display: none;
    margin-top: 100vh;

    &.mobile {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      flex-direction: column;
      width: unset;
      height: 100vh;
      padding-top: 62px;
      background-color: $color-light-grey;
    }

    &.mobileArrive {
      margin-top: 0px;
      animation-name: arrive;
      animation-duration: 1s;
      animation-timing-function: ease-in;
    }

    &.mobileHide {
      margin-top: 100vh;
      animation-name: hide;
      animation-duration: 1s;
      animation-timing-function: ease-in;
    }
  }
}

.mobileSidePanelContainer {
  display: none;
  @include mobile {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.mobileSidePanelButton {
  display: none;
  @include mobile {
    display: flex;
    margin-bottom: 5px;
    outline: none;
    user-select: none;
    border: none;
    background-image: url("/assets/images/down_arrow.png");
    width: 45px;
    height: 45px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
